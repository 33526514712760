import * as React from "react"
import { Link } from "gatsby"

const Categories = ({ data }) => {
  return (
    <div className="overflow-y-auto whitespace-no-wrap scroll-hidden text-center">
      {data.map((item, index) => (
        <React.Fragment key={item.node.id}>
          <Link
            to={`/blog/category/${item.node.slug}`}
            activeClassName="!text-blue-pacific"
            className="inline-block py-3 hover:text-blue-pacific mr-4 md:my-0">
            {item.node.name}
          </Link>

          {data.length - 1 !== index && (
            <span className="text-blue mr-4">·</span>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default Categories

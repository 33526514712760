import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BeatLoader from "react-spinners/BeatLoader"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "../components/ui/grid"
import CardPost from "../components/ui/card-post"
import Categories from "../components/ui/categories"

import defaultThumbnail from "../images/thumbnail-loadmill.png"

const IndexBlog = ({ location, perLoad }) => {
  const query = useStaticQuery(
    graphql`
      query {
        data: contentfulBlogHome {
          title
          description
          featuredImage {
            resize(width: 512, quality: 80) {
              src
            }
          }
        }
        allContentfulPost(sort: { fields: publishDate, order: DESC }) {
          edges {
            node {
              id
              title
              slug
              description
              publishDate(formatString: "MMM Do, YYYY")
              featuredImage {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              author {
                name
                photo {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              category {
                name
                slug
              }
              content {
                raw
              }
            }
          }
        }
        allContentfulCategory {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    `
  )

  const categories = query.allContentfulCategory.edges
  const allPosts = query.allContentfulPost.edges

  const [posts, setPosts] = React.useState([...allPosts.slice(0, perLoad)])
  const [loadMore, setLoadMore] = React.useState(false)
  const [hasMore, setHasMore] = React.useState(allPosts.length > perLoad)
  const loadRef = React.useRef()

  // Handle intersection with load more div
  const handleObserver = entities => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  // Initialize the intersection observer API
  React.useEffect(() => {
    var options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleObserver, options)

    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  // Handle loading more articles
  React.useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = posts.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + perLoad)
        : []

      setPosts([...posts, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  // Check if there is more
  React.useEffect(() => {
    const isMore = posts.length < allPosts.length
    setHasMore(isMore)
  }, [posts]) //eslint-disable-line

  const title = query?.data?.title || ""
  const description = query?.data?.description || ""
  const thumbnail = query?.data?.featuredImage?.resize?.src || defaultThumbnail

  return (
    <Layout location={location}>
      <Seo
        title={title}
        description={description}
        url={location.href}
        image={thumbnail}
      />

      <Container className="mt-12 mb-2 max-w-6xl text-center md:text-left">
        <div className="mt-6 flex flex-wrap max-w-6xl md:mt-12">
          <Categories data={categories} />
        </div>
      </Container>

      <div className="pt-12 pb-24 bg-gray-100 lg:pt-20">
        <Container className="max-w-6xl">
          <div className="grid gap-y-6 grid-cols-1 md:grid-cols-2 lg:gap-6 lg:grid-cols-3">
            {posts.map((item, index) => {
              return (
                <CardPost
                  key={item.node.id}
                  title={item.node.title}
                  category={item.node.category.name}
                  author={item.node.author}
                  date={item.node.publishDate}
                  content={item.node.description}
                  to={item.node.slug}
                  img={item.node.featuredImage.gatsbyImageData}
                  full={index === 0}
                />
              )
            })}
          </div>

          <div ref={loadRef} className="text-center pt-12">
            <BeatLoader color="black" loading={hasMore} size={20} />
          </div>
        </Container>
      </div>
    </Layout>
  )
}

IndexBlog.defaultProps = {
  perLoad: 15,
}

export default IndexBlog
